<template>
    <div class="input-text-component light-shadow">
        <label class="hidden-label" >{{ autofillLabel || label}}</label>
        <input
            class="semi-bold-paragraph"
            :type="displayType"
            :value="modelValue"
            @input="updateInput"
            :name="label"
            :class="{'input-error': error}"
            ref="inputEl" >
        <p class="semi-bold-paragraph input-placeholder" v-if="!modelValue || modelValue.length == 0">{{label}}<span class="orange-text" v-if="required">*</span></p>
        <img v-if="type == 'password'" @click="togglePassword" class="password-state" src="@/assets/eye.svg" alt="icon eyes display hide password">
    </div>
</template>
<script>
import { ref } from 'vue'
export default {
    setup (props) {
        const inputEl = ref(null)
        const displayType = ref(props.type)
        return { inputEl, displayType }
    },
    props: {
        modelValue: String,
        type: {
            default: 'text',
            type: String
        },
        label: String,
        autofillLabel: String,
        required: Boolean,
        error: Boolean
    },
    emits: ['update:modelValue'],
    methods: {
        updateInput(event) {
            let value = event.target.value
            this.$emit('update:modelValue', value)
        },
        togglePassword () {
            this.displayType = this.displayType == 'password' ? 'text' : 'password'
        }
    }
}
</script>
<style lang="scss">
@use "../assets/library.scss";

.input-text-component{
    border-radius: 5px;
    display: flex;
    background: white;
    height: 48px;
    width: 100%;
    position: relative;
    margin: 10px 0 ;
    box-shadow: library.$box-shadow;
    .hidden-label{
        display: none;
    }
    input:focus, input{
        outline: none;
        background: white;
    }
    [type="text"],[type="password"]{
        border-radius: 5px;
        border:none;
        height: 48px;
        padding: 0 10px;
        font-size: 14px;
        width: calc(100% - 20px);
    }
    .input-placeholder{
        position: absolute;
        top:50%;
        left:10px;
        font-size: 14px;
        margin: 0;
        transform: translate(0,-50%);
        pointer-events: none;
    }
    .input-error{
        border: #FF8000 1px solid;
    }
    .password-state{
        width: 18px;
        display: block;
        margin: 0 8px;
        object-fit: contain;
    }
}

@media screen and (max-width: 764px) {
    .input-text-component{
        width: 100%;
        margin: 10px 0;
    }

}
</style>