<template>
    <div class="cta-wrapper">
        <p class="cta-label">{{label}}</p>
    </div>
</template>
<script>
export default {
    props: {
        label: String
    }
}
</script>
<style lang="scss">
.cta-wrapper{
    cursor: pointer;
    border-radius: 3px;
    height: 48px;
    display: inline-block;
    padding: 0 30px;
    text-align: center;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    box-sizing: border-box;
    background: linear-gradient(90deg, #FF7D63 0%, #FF325D 100%);
    .cta-label{
        display: inline-block;
        font-family: Cocogoose-semi-light;
        color: white;
        line-height: 48px;
        margin: 0;
        letter-spacing: 0.5px;
    }
}
</style>