<template>
    <div class="error-wrapper">
        <div class="error-line"></div>
        <p class="error-label">{{label}}</p>
    </div>
</template>
<script>
export default {
    props: {
        label: String
    }
}
</script>
<style lang="scss">
@use "../assets/library.scss";

.error-wrapper{
    cursor: pointer;
    border-radius: 3px;
    display: flex;
    margin: 10px 0;
    min-height: 29px;
    align-items: stretch;
    justify-content: flex-start;
    .error-line {
        background: linear-gradient(90deg, #FF7D63 0%, #FF325D 100%);
        width: 6px;
        border-radius:100px;
        margin-right:10px;
        flex-shrink: 0;
    }
    
    .error-label{
        display: inline-block;
        font-family: Raleway-bold;
        font-style: italic;
        font-size: 11px;
        color: library.$text-color;
        line-height: 16px;
        margin: 5px 0;
        letter-spacing: 0.5px;
    }
}
</style>