<template>
	<div class="auth-app">
		<img src="@/assets/karos_logo.svg" alt="">
		<div class="form-wrapper" :class="{'loading': isLoading}">
			<h2 class="form-title">Connectez-vous à votre compte</h2>
			<div class="input-wrapper">
				<baseInputText v-model="email" label="Email"/>
                <baseError v-if="filtered_errors.indexOf('email_empty') !== -1 "  :label="errors.empty" />
                <baseError v-if="filtered_errors.indexOf('email_invalid') !== -1 "  :label="errors.invalid" />

                <baseInputText v-model="password" label="Mot de passe" type="password" />
                <baseError v-if="filtered_errors.indexOf('password') !== -1 " :label="errors.empty" />
			</div>
            <baseError v-if="filtered_errors.indexOf('connect') !== -1 " :label="errors.connect" />
		</div>
		<baseButton @click="checkForm" label="Se connecter" :class="{'loading': isLoading}"/>
	</div>
</template>

<script setup>
import baseButton from '@/components/baseButton'
import baseInputText from '@/components/baseInputText'
import baseError from '@/components/baseError'
import { validateEmail, isEmptyInput } from '@/lib/validators'
import { API_URL } from "@/lib/const"
import { ref, onMounted } from 'vue'
import Axios from 'axios'

let isLoading = false
let { client_id, redirect_uri } = getUrlParameters()

const email = ref('')
const password = ref('')
const filtered_errors = ref([])
const errors = {
    empty: 'Ce champs est obligatoire',
    invalid: 'L’email ne semble pas au bon format',
    connect: "Identifiant ou mot de passe non reconnu. Veuillez utiliser les mêmes identifiants que sur votre compte Karos.",
    impossible: "Connexion impossible"
}

function checkForm () {
    reset()
    if (!isEmptyInput(email.value)) filtered_errors.value.push('email_empty')
    else if (!validateEmail(email.value)) filtered_errors.value.push('email_invalid')
    if (!isEmptyInput(password.value)) filtered_errors.value.push('password')
    if (filtered_errors.value.length != 0) return
    submit()
}

function reset () {
    filtered_errors.value = []
    isLoading = false
}

async function submit () {
    isLoading = true
    let url = API_URL[process.env.NODE_ENV]
    try {
        let res = await Axios.post(`${url}/auth/ext/`, {
            email: email.value,
            password: password.value,
            client_id: client_id,
            redirect_uri: redirect_uri
        })
        let response = res
        if (response.status != 200) return reset()
        window.location = response.data.redirect_uri_with_token
    } catch (err) {
        isLoading = false
        let error = err.toJSON()
        if (error.status == 401) filtered_errors.value.push('connect')
        if (error.status == 400) filtered_errors.value.push('impossible')
    }
    
}

function getUrlParameters () {
    const urlParams = new URLSearchParams(window.location.search);
    client_id = urlParams.get('client_id')
    redirect_uri = urlParams.get('redirect_uri')
    return { client_id, redirect_uri }
}

onMounted(getUrlParameters)
</script>

<style lang="scss">
@use "assets/library.scss";

.loading{
    cursor: default;
    opacity: 0.7;
}
.auth-app{
    padding: 20px 0;
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    width: 100%;
    align-content: space-between;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .form-wrapper{
        background:#F6F7F9;
        border-radius:10px;
        width: 90%;
        padding:10px;
        box-sizing: border-box;
        color: library.$text-color;
        box-shadow: library.$box-shadow;
        .form-title{
            text-align: center;
            font-size: 16px;
            font-family: raleway-bold;
            color: library.$title-color;
        }
    }
    .cta-wrapper{
        width: 90%;

    }
}
</style>
